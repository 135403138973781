import { UseFormReturn } from '../../data/forms/externalCardForm';
import { CorporateCardUserMetadata } from '../../data/useCreateCorporateCardUserController';
import { MultipleCards } from '../MultipleCardsSection';
import { SingleCard } from '../SingleCardSection';
import * as SC from './styled';

type CardDetailsProps = {
  isFlashCard: boolean;
  externalCardForm: UseFormReturn;
  flashCardForm: any; // TODO: update this type
  createCorporateCardUserMetadata: CorporateCardUserMetadata;
};

export function CardDetails(props: CardDetailsProps) {
  const { isSingleCard } = props.createCorporateCardUserMetadata;

  return (
    <SC.Container>
      {isSingleCard ? (
        <SingleCard
          isFlashCard={props.isFlashCard}
          externalCardForm={props.externalCardForm}
          flashCardForm={props.flashCardForm}
        />
      ) : (
        <MultipleCards isFlashCard={props.isFlashCard} />
      )}
    </SC.Container>
  );
}
