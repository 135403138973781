import Icon from '@frontend/components/Icon';
import Fuse from 'fuse.js';
import { type DebouncedFunc } from 'lodash';
import debounce from 'lodash.debounce';
import React from 'react';
import { ComboBoxField } from './ComboBoxField';

const MAX_VISIBLE_TAGS = 2;

type ComboBoxFieldProps = React.ComponentProps<typeof ComboBoxField>;
type ComboBoxFieldOption = ComboBoxFieldProps['options'][number];
export type ComboBoxFieldState = 'DEFAULT' | 'FOCUSED' | 'DISABLED' | 'ERROR';

export function useComboBoxFieldController(input: ComboBoxFieldProps) {
  /* --------------------------- Option selection --------------------------- */
  const selectedOptions = input.value;
  const setSelectedOptions = input.onChange;
  const enabledOptionsLength = input.options.filter(
    (option) => !option.disabled,
  ).length;

  function handleToggleOption(
    selectedValue: ComboBoxFieldOption['value'],
    checked: boolean,
  ) {
    const setCopy = new Set(selectedOptions);
    if (checked) {
      setCopy.add(selectedValue);
    } else {
      setCopy.delete(selectedValue);
    }
    setSelectedOptions(setCopy);
  }

  function handleToggleAll(checked: boolean) {
    if (checked) {
      setSelectedOptions(
        new Set(
          input.options
            .filter((option) => !option.disabled)
            .map((option) => option.value),
        ),
      );
    } else {
      setSelectedOptions(new Set());
    }
  }

  const allSelected = enabledOptionsLength === selectedOptions.size;
  const hasSelected = selectedOptions.size > 0;
  /* ----------------------------- Input filter ----------------------------- */
  const [filteredOptions, setFilteredOptions] = React.useState<
    Readonly<Array<ComboBoxFieldOption>>
  >(input.options);

  const handleFilter: DebouncedFunc<(filter: string) => void> =
    React.useMemo(() => {
      const fuse = new Fuse(input.options, {
        keys: ['title', 'additionalKey'],
        threshold: 0.3,
      });

      return debounce(
        (filter: string) => {
          setFilteredOptions(
            filter
              ? fuse.search(filter).map((result) => result.item)
              : input.options,
          );
        },
        500,
        {
          maxWait: 1500,
        },
      );
    }, [input.options]);

  React.useEffect(() => {
    handleFilter('');
  }, [input.options]);

  /* ------------------------------ Input state ----------------------------- */
  const [inputState, setInputState] =
    React.useState<ComboBoxFieldState>('DEFAULT');

  React.useEffect(() => {
    if (input.disabled) {
      setInputState('DISABLED');
      return;
    }
    if (input.error) {
      setInputState('ERROR');
      return;
    }
    setInputState('DEFAULT');
  }, [input.disabled, input.error]);

  /* ------------------- Popover anchoring and visibility ------------------- */
  const [popoverAnchor, setPopoverAnchor] =
    React.useState<HTMLButtonElement | null>(null);

  function handleOpenPopover(event: React.MouseEvent<HTMLButtonElement>) {
    if (inputState === 'DISABLED') return;
    setInputState('FOCUSED');
    setPopoverAnchor(event.currentTarget);
  }

  function handleClosePopover() {
    setInputState(input.error ? 'ERROR' : 'DEFAULT');
    setPopoverAnchor(null);
  }

  const popoverOpen = Boolean(popoverAnchor);
  const popoverId = popoverOpen ? 'simple-popover' : undefined;

  /* ----------------------------- Visible tags ----------------------------- */
  const visibleTags = React.useMemo(() => {
    const electedOptions: Array<ComboBoxFieldOption> = [];
    for (
      let i = 0;
      i < input.options.length && electedOptions.length < MAX_VISIBLE_TAGS;
      i++
    ) {
      if (selectedOptions.has(input.options[i].value)) {
        electedOptions.push(input.options[i]);
      }
    }
    return electedOptions;
  }, [input.options, selectedOptions]);

  const collapsedCount = selectedOptions.size - MAX_VISIBLE_TAGS;
  /* --------------------------------- Icon --------------------------------- */

  /* -------------------------------- Return -------------------------------- */
  return {
    popover: {
      anchor: popoverAnchor,
      handleOpen: handleOpenPopover,
      handleClose: handleClosePopover,
      popoverOpen,
      popoverId,
    },
    selection: {
      selectedOptions,
      allSelected,
      hasSelected,
      handleToggleOption,
      handleToggleAll,
    },
    state: inputState,
    tags: {
      collapsedCount,
      visibleTags,
    },
    filter: {
      filteredOptions,
      handleFilter,
    },
    field: {
      iconColor: iconColorMap[inputState],
    },
  };
}

const iconColorMap = {
  DEFAULT: 'neutral_50',
  DISABLED: 'neutral_50',
  ERROR: 'error_50',
  FOCUSED: 'secondary_70',
} as const satisfies Readonly<
  Record<ComboBoxFieldState, React.ComponentProps<typeof Icon>['color']>
>;
