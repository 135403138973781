import DefaultModal from '@frontend/components/modals/DefaultModal';
import ModalService from '@frontend/services/ModalService';
import { useCreateCorporateCardUserController } from './data/useCreateCorporateCardUserController';

type Props = {
  isFlashCard?: boolean;
};

function CreateCorporateCardUser({ isFlashCard = true }: Props) {
  const modalController = ModalService.useModalController();

  const { viewComponent, viewActions, isLoading } =
    useCreateCorporateCardUserController({ isFlashCard });

  return (
    <DefaultModal
      title="Informar os dados"
      children={viewComponent}
      buttons={viewActions}
      isOpen={modalController.visible}
      onClose={modalController.remove}
      loading={isLoading}
    />
  );
}

export default ModalService.create(CreateCorporateCardUser);
