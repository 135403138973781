import Icon from '../Icon';
import Typography from '../Typography';
import * as SC from './styled';

type HighlightProps = {
  children: string;
  size?: 'default' | 'compact';
  variant: 'announcement' | 'success' | 'warning' | 'error';
};

export function Highlight(props: HighlightProps) {
  const icon = mappedIcon[props.variant];

  return (
    <SC.Container size={props.size}>
      <Icon
        name={icon.name}
        color={icon.color}
        size={props.size === 'compact' ? 16 : 48}
        background={props.size === 'compact' ? undefined : icon.background}
      />

      <Typography.Body4 weight={700} color={icon.color}>
        {props.children}
      </Typography.Body4>
    </SC.Container>
  );
}

const mappedIcon: Record<
  HighlightProps['variant'],
  React.ComponentProps<typeof Icon>
> = {
  announcement: {
    name: 'IconSparkles',
    color: 'secondary_50',
    background: 'default',
  },
  error: {
    name: 'IconX',
    color: 'error_40',
    background: 'error',
  },
  success: {
    name: 'IconCircleCheck',
    color: 'success_40',
    background: 'success',
  },
  warning: {
    name: 'IconAlertCircle',
    color: 'error_40',
    background: 'error',
  },
} as const;
