import { Highlight } from '@frontend/components/Highlight';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import * as SC from './styled';

type ModalHeaderProps = {
  bgImageUrl?: string;
  title: string;
  subtitle?: string;
  highlight?: {
    description: string;
    size?: 'default' | 'compact';
    /**
     * @default announcement
     */
    variant?: 'announcement' | 'success' | 'warning';
  };
};

export function ModalHeader(props: ModalHeaderProps) {
  return (
    <div>
      {props.bgImageUrl && <SC.BgImage src={props.bgImageUrl} />}

      <SC.TitleContainer>
        {props.highlight && (
          <>
            <Highlight
              size={props.highlight.size}
              variant={props.highlight.variant ?? 'announcement'}
            >
              {props.highlight.description}
            </Highlight>

            <Spacer y="xs2" />
          </>
        )}

        <Typography.Headline8 color="neutral_10">
          {props.title}
        </Typography.Headline8>

        <Spacer y="xs4" />

        {props.subtitle && (
          <>
            <Typography.Body4 color="neutral_40">
              {props.subtitle}
            </Typography.Body4>

            <Spacer y="xs2" />
          </>
        )}
      </SC.TitleContainer>
    </div>
  );
}
