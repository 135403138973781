import { DateRangeField } from '@flash-tecnologia/hros-web-ui-v2';
import { FocusedFlow } from '@frontend/components/FocusedFlow';
import ModalService from '@frontend/services/ModalService';
import { trpc } from '@frontend/trpc';
import { DateTime } from 'luxon';
import React from 'react';
import { REPORTS_CORPORATE_CARD_STATEMENT } from '../../consts';
import useForm from '../../form/useForm';
import StepFeedback from '../components/StepFeedback';
import StepGenerate from '../components/StepGenerate';
import disabled from '../shared/disabledDate';
import { FormSchema, FormSchemaOutput } from './schema';
import { CorporateCardStatementTable } from './table/CorporateCardStatementTable';

type Input = {
  initialValue?: Omit<FormSchemaOutput, 'template'>;
  step?: 1 | 2;
};

/**
 * Focused Flow responsible for creating a request for a corporate card statement report
 **/
function CorporateCardStatement(input: Input) {
  const [step, setStep] = React.useState(input.step ?? 1);
  const reportTemplate = 'expense-employees-statement';

  const context = trpc.useContext();

  const modalController = ModalService.useModalController();
  const form = useForm({
    onSuccess: () => setStep(2),
    reportTemplate,
    defaultValues: input.initialValue,
    formSchema: FormSchema,
  });

  function content() {
    switch (step) {
      case 1:
        return (
          <StepGenerate
            info={{
              title: REPORTS_CORPORATE_CARD_STATEMENT,
              description:
                'Para criar o Report, selecione o formato que deseja receber e os filtros para configurá-lo',
            }}
            form={form}
            filters={[
              {
                name: 'dateInterval',
                Component: ({ onChange, value }) => (
                  <DateRangeField
                    label="Período selecionado"
                    onChange={onChange}
                    initialValue={value}
                    fullWidth
                    pickerProps={{
                      disabled,
                      toDate: DateTime.now().minus({ day: 1 }).toJSDate(),
                    }}
                  />
                ),
              },
            ]}
            Table={CorporateCardStatementTable}
          />
        );
      case 2:
        return (
          <StepFeedback
            fileType={form.getValues('format')}
            startDate={form.getValues('dateInterval.from')}
            endDate={form.getValues('dateInterval.to')}
            reportTemplate={reportTemplate}
          />
        );
    }
  }

  function footer() {
    switch (step) {
      case 1:
        return {
          leftActions: {
            label: 'Voltar',
            onClick: () => {
              modalController.remove();
            },
          },
          rightActions: [
            {
              variant: 'primary' as const,
              label: 'Criar Report',
              isDisabled: !form.validSchema,
              isLoading: form.isSubmitting,
              onClick: () => {
                void form.onSubmit();
              },
            },
          ],
        };
      default:
        return {
          rightActions: [
            {
              variant: 'primary' as const,
              label: 'Voltar para Relatórios de dados',
              isDisabled: form.isSubmitting,
              isLoading: false,
              onClick: () => {
                void context.company.reports.search.invalidate();
                modalController.remove();
              },
            },
          ],
        };
    }
  }

  return (
    <FocusedFlow
      headerMetadata={{
        breadcrumbs: [
          { text: 'Insights e Reports' },
          { text: `Criar Report de ${REPORTS_CORPORATE_CARD_STATEMENT}` },
        ],
      }}
      content={content()}
      footer={footer()}
    />
  );
}

export default ModalService.create(CorporateCardStatement);
