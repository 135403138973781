import {
  Icons,
  IconsProps,
  LinkButton as UIButton,
  LinkButtonProps as UIButtonProps,
} from '@flash-tecnologia/hros-web-ui-v2';

type LinkButtonProps = {
  id?: string;
  variant: UIButtonProps['variant'];
  href?: string;
  onClick?(): void;
  children: string;
  target?: UIButtonProps['target'];
  icon?: IconsProps['name'];
};

export default function LinkButton(props: LinkButtonProps) {
  return (
    <UIButton {...props}>
      {props.children}

      {props.icon && <Icons name={props.icon} />}
    </UIButton>
  );
}
