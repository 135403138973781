import { IconsProps, PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import { Drawer as DrawerMUI } from '@mui/material';
import { DrawerHeader } from './DrawerHeader';
import * as SC from './styled';

type Props = {
  anchor: React.ComponentProps<typeof DrawerMUI>['anchor'];
  visible: boolean;
  title: React.ReactNode;
  icon?: IconsProps['name'];
  close: () => void;
  children: React.ReactNode;
};

export default function Drawer(props: Props) {
  return (
    <DrawerMUI open={props.visible} anchor={props.anchor}>
      <SC.CloseContainer>
        <PillButton
          variant="default"
          icon="IconX"
          size="small"
          onClick={props.close}
        />
      </SC.CloseContainer>

      <DrawerHeader title={props.title} icon={props.icon} />

      <SC.Body>{props.children}</SC.Body>
    </DrawerMUI>
  );
}
