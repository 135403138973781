import { Mutable } from '@corporate-card/ts-utils/helper-types';
import {
  Pagination,
  PillButton,
  Spinner,
  Tooltip,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import * as ReactTable from '@tanstack/react-table';
import React from 'react';
import EmptyFilteredTableSVG from '../../assets/table/empty_filtered_table.svg';
import EmptyTableSVG from '../../assets/table/empty_table.svg';
import Icon from '../Icon';
import Spacer from '../Spacer';
import TagPaginationSelect from '../TagPaginationSelect';
import Flex from '../frames/Flex';
import customFilters from './customFilters';
import * as SC from './styles';

type Props<Columns> = {
  /** Columns setup */
  columns: Array<
    ReactTable.TableOptions<Columns>['columns'][number] & {
      isSticky?: true;
      isHidden?: boolean;
    }
  >;
  /** Displayed data */
  data: Readonly<ReactTable.TableOptions<Columns>['data']>;
  /** Messages presented when there are no results available */
  emptyState: {
    emptyText: string;
    filteredEmptyText: string;
  };
  /** Fetching data */
  loading: boolean;
  /** Array of column filters */
  filter?: ReactTable.ColumnFiltersState;
  /** String used as global filter */
  searchFilter?: string;
  /** Tag filters' component */
  TableFilters?: React.ReactNode;
  /** Text input's component */
  TableSearchFilter?: React.ReactNode;
  /** Enables the table pagination */
  enablePagination?: boolean;
  /** Pagination's page size options */
  pageSizeOptions?: { value: number; label: string }[];
};

export default function TableClient<Columns>(props: Props<Columns>) {
  const table = ReactTable.useReactTable({
    columns: props.columns,
    data: props.data as Mutable<typeof props.data>,
    state: {
      columnFilters: props.filter ?? [],
      globalFilter: props.searchFilter,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: props.pageSizeOptions?.[0]?.value ?? 10,
      },
    },
    getCoreRowModel: ReactTable.getCoreRowModel(),
    getPaginationRowModel: props.enablePagination
      ? ReactTable.getPaginationRowModel()
      : undefined,
    getFilteredRowModel: ReactTable.getFilteredRowModel(),
    getSortedRowModel: ReactTable.getSortedRowModel(),
    enableFilters: true,
    filterFns: customFilters,
  });

  const tableState = table.getState();
  const tableRows = table.getRowModel().rows;

  const RenderedTable = () => {
    // Loading
    if (props.loading)
      return (
        <SC.EmptyTable>
          <Spinner variant="primary" size={48} />
        </SC.EmptyTable>
      );

    // Empty
    if (!tableRows.length)
      return (
        <SC.EmptyTable>
          {tableState.columnFilters?.length || tableState.globalFilter ? (
            <EmptyFilteredTableSVG />
          ) : (
            <EmptyTableSVG />
          )}
          <Typography variant="body3">
            {tableState.columnFilters?.length || tableState.globalFilter
              ? props.emptyState.filteredEmptyText
              : props.emptyState.emptyText}
          </Typography>
        </SC.EmptyTable>
      );

    let allHeaderElements: Array<number> = [];
    return (
      <SC.Table>
        {/* ---------------------------- Header ---------------------------- */}
        <SC.TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers
                .filter((h) => !h.column.columnDef['isHidden'])
                .map((header, index) => (
                  <SC.Th
                    colSpan={header.colSpan}
                    sticky={header.column.columnDef['isSticky']}
                    width={header.getSize()}
                    ref={(element: HTMLTableHeaderCellElement) => {
                      if (element) {
                        const { headers } = headerGroup;
                        if (allHeaderElements.length === 0) {
                          const elements: HTMLTableHeaderCellElement[] =
                            Array.prototype.slice.call(
                              element.parentElement!.children,
                            );
                          allHeaderElements = elements.map(
                            ({ clientWidth }) => clientWidth,
                          );
                        }

                        if (
                          index <= headers.length / 2 - 1 &&
                          header.column.columnDef['isSticky']
                        ) {
                          element.style.left = `${element.offsetLeft}px`;
                        }
                        if (
                          index >= headers.length / 2 + 1 &&
                          header.column.columnDef['isSticky']
                        ) {
                          element.style.right = `${allHeaderElements
                            .slice(index + 1)
                            .reverse()
                            .reduce((offset, width) => offset + width, 0)}px`;
                        }
                      }
                    }}
                    key={header.id}
                  >
                    {header.isPlaceholder ? null : (
                      <Flex align="center" justify="space-between" gap="xs4">
                        <Flex align="center" gap="xs4">
                          {ReactTable.flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {header.column.columnDef.meta?.tooltip && (
                            <Tooltip
                              title={header.column.columnDef.meta.tooltip}
                              arrow
                            >
                              <div>
                                <Icon
                                  name="IconInfoCircle"
                                  size={16}
                                  color="neutral_40"
                                />
                              </div>
                            </Tooltip>
                          )}
                        </Flex>
                        {header.column.getCanSort() && (
                          <PillButton
                            icon={mapSortingIcon(header.column.getIsSorted())}
                            size="small"
                            variant="default"
                            type="primary"
                            onClick={header.column.getToggleSortingHandler()}
                          />
                        )}
                      </Flex>
                    )}
                  </SC.Th>
                ))}
            </tr>
          ))}
        </SC.TableHeader>
        {/* ----------------------------- Body ----------------------------- */}
        <tbody>
          {tableRows.map((row) => (
            <tr key={row.id}>
              {row
                .getVisibleCells()
                .filter((r) => !r.column.columnDef['isHidden'])
                .map((cell, index) => (
                  <SC.TableCell
                    width={cell.column.getSize()}
                    sticky={cell.column.columnDef['isSticky']}
                    ref={(element: HTMLTableDataCellElement) => {
                      if (element) {
                        const rowsLength = row.getVisibleCells().length;
                        if (
                          index <= rowsLength / 2 - 1 &&
                          cell.column.columnDef['isSticky']
                        ) {
                          element.style.left = `${element.offsetLeft}px`;
                        }
                        if (
                          index >= rowsLength / 2 + 1 &&
                          cell.column.columnDef['isSticky']
                        ) {
                          element.style.right = `${allHeaderElements
                            .slice(index + 1)
                            .reverse()
                            .reduce((offset, width) => offset + width, 0)}px`;
                        }
                      }
                    }}
                    key={cell.id}
                  >
                    {ReactTable.flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext(),
                    )}
                  </SC.TableCell>
                ))}
            </tr>
          ))}
        </tbody>
        {/* ---------------------------- Footer ---------------------------- */}
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers
                .filter((h) => !h.column.columnDef['isHidden'])
                .map((header) => (
                  <th key={header.id} style={{ width: header.getSize() }}>
                    {header.isPlaceholder
                      ? null
                      : ReactTable.flexRender(
                          header.column.columnDef.footer,
                          header.getContext(),
                        )}
                  </th>
                ))}
            </tr>
          ))}
        </tfoot>
      </SC.Table>
    );
  };

  return (
    <>
      {!!props.TableSearchFilter && (
        <>
          <Spacer y="s" useMargin />
          {props.TableSearchFilter}
          <Spacer y="s" useMargin />
        </>
      )}
      {!!props.TableFilters && (
        <>
          <SC.TableFilterContainer>
            <SC.FilterText variant="body3">Filtrar por</SC.FilterText>
            {props.TableFilters}
          </SC.TableFilterContainer>
          <Spacer y="xs" useMargin />
        </>
      )}
      <SC.TableContainer>
        <RenderedTable />
      </SC.TableContainer>
      {props.enablePagination && (
        <SC.PaginationContainer>
          <TagPaginationSelect
            selectedValue={tableState.pagination.pageSize}
            options={props.pageSizeOptions ?? defaultPaginationOptions}
            onChange={(newValue) => {
              table.setPagination({ pageIndex: 0, pageSize: newValue });
            }}
          />
          <Pagination
            page={tableState.pagination.pageIndex + 1}
            onChange={(_event, page) => {
              table.setPageIndex(page - 1);
            }}
            count={table.getPageCount()}
          />
        </SC.PaginationContainer>
      )}
    </>
  );
}

const defaultPaginationOptions = [
  { label: '10 itens', value: 10 },
  { label: '25 itens', value: 25 },
  { label: '50 itens', value: 50 },
  { label: '100 itens', value: 100 },
  { label: '250 itens', value: 250 },
];

function mapSortingIcon(
  sorting: 'asc' | 'desc' | false,
): React.ComponentProps<typeof PillButton>['icon'] {
  if (sorting === 'asc') {
    return 'IconArrowDown';
  }
  if (sorting === 'desc') {
    return 'IconArrowUp';
  }
  return 'IconArrowsSort';
}
